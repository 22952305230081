#terms {
    font-size: 16px;
    text-align: justify;
    margin-bottom: 75px;
    ol {
        list-style-type: none;
        counter-reset: item;
        padding: 0;
    }

    li {
        display: table;
        counter-increment: item;
        margin-bottom: 0.6em;
    }
    h2{
        font-weight: bold;
        text-align: center;
        font-size: 24px;
        text-decoration: underline;
        margin-block: 75px;
    }
    h3{
        font-weight: bold;
        text-align: center;
        font-size: 20px;
        text-decoration: underline;
        margin-block: 32px;
    }
    p{
        margin-block: 10px;
    }
}

#terms li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;    
}

#terms >li li:before {
    content: counters(item, ".") " ";
}

#terms div > ol > li{
    font-weight: bold;

    >ol>li{
        font-weight: normal;
    }
    
}