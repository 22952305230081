.alertCont {
    margin: 10px 30px;
    padding-bottom: 5px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        height: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #A9A9A9;
        border-radius: 30px;
    }


    article {
        flex-shrink: 0;
        padding: 10px;
        width: 300px;
        height: 95%;
        font-size: 18px;
        color: white;
        border-radius: 15px;
        background-color: rgba(219, 0, 45, 0.795);
        margin-inline-end: 20px;
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    svg {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 20px;
        cursor: pointer;
    }
}