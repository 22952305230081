.record{
    margin: 50px 0;
}
.record table{
    width: 100%;
    margin-top: 20px;
    td,th{
        text-align: center;
    }
}


.record input{
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
    text-align: center;
}

.record *{
    font-size: 18px;
}

#invisibleTable{
    visibility: hidden ;
    height: 0;
}

