.sidebarStatus {
    display: grid;
    grid-template-columns: 100px auto;
    font-size: 40px;
    transition: all 400ms;
    min-height: 100vh;

    &.active {
        grid-template-columns: 230px auto;
    }
}


.sidebar {
    background-image: linear-gradient(180deg, #6A5BE2 10%, #1b1c30 100%);
    // background-color: #3B38C6;

    position: sticky;
    height: 100%;

    h3 {
        text-transform: uppercase;
        font-size: 12px;
        padding-inline: 16px;
        font-weight: 800;
        color: rgba(255, 255, 255, 0.4);
        text-align: center;
        margin-block: 15px;

        position: relative;
        padding-bottom: 10px;

        &::after {
            content: '';
            position: absolute;
            width: 80%;
            height: 1px;
            background-color: rgba(255, 255, 255, 0.3);
            bottom: 0;
            right: 50%;
            transform: translateX(50%);

        }

    }
}

.sidebarBtn {
    position: absolute;
    top: 80px;
    right: -15px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3d2eae;
    border: none;
    outline: none;
    transition: all 600ms;
    z-index: 5;
}

.arrow {
    width: 20px;
    color: white;
}

.logoContent {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-block: 24px;

    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 50%;
        width: 95%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.1);
        transform: translateX(50%);
    }

    img {
        transition: all 300ms ease;
    }

    h2 {
        color: white;
        font-weight: 800;
    }
}


.imgContent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalLinkContainer {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
    padding: 16px;
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    cursor: pointer;

    span {
        margin-left: 8px;
    }

    svg {
        font-size: 18px;
    }
}

.big {
    justify-content: center;

    svg {
        font-size: 22px;
    }
}

.linkContainer {
    font-size: 16px;
    margin: 20px auto;

    a {
        display: flex;
        justify-content: center;
        color: #F0F0F0;
        background-color: #9393934d;
        padding: 6px 4px;
        border-radius: 5px;
        transition: all 400ms ease;

        &:hover {
            background-color: rgba(210, 210, 210, 0.3);
            color: #F0F0F0;
            transform: translateY(-3px);
        }
    }
}

.linkContainer a.active {
    background-color: #d2d2d274;

}

.jwtLink {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
    width: 80%;
    margin: 0 auto;
    padding: 2px;
    cursor: pointer;

    p {
        font-size: 14px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 85%;
    }
}

.jwtBorder {
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 4px;
}

.tokenContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
        font-size: 14px;
        margin-bottom: 5px;
        color: rgba(255, 255, 255, 0.6);
    }

    span {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.6);
        text-align: center;
    }
}

.form-group {
    margin-block: 10px;
}

.form-control {
    font-size: 16px;
}


// Admin Body
.adminBody {
    display: grid;
    grid-template-rows: 70px 150px auto;
    max-height: 100vh;
    background-color: #ececec;
}

//#region Flow Contact
.flowCont {
    display: flex;
    overflow-x: scroll;
    padding-block: 20px;
    margin: 20px;
    margin-top: 0;

    &::-webkit-scrollbar {
        height: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #A9A9A9;
        border-radius: 30px;
    }

    article {
        width: 350px;
        height: 100%;
        border-radius: 20px;
        padding: 20px 15px;
        margin: 0 25px;
        overflow: hidden;
        flex-shrink: 0;
        position: relative;
    }

    h3 {
        font-size: 20px;
        font-weight: bold;
        color: #292929;
        margin: 0;
        padding-bottom: 10px;
        user-select: none;
        text-align: center;
        border-bottom: 1px solid #292929;

    }
}

.flowContactCont {
    overflow-y: scroll;
    max-height: calc(100% - 40px);
    padding-right: 10px;
    margin: 10px 0;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #A9A9A9;
        border-radius: 30px;
    }

    >i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 75px;
        color: #A9A9A9;
        animation: upDown infinite 3s ease-in-out;
    }
}

.flowContactCard {
    display: grid;
    grid-template-areas:
        "created created created"
        "name enterprise btn"
        "mark mmsi btn";
    grid-template-columns: 6fr 3fr 2fr;
    grid-template-rows: 14px auto auto;

    height: 80px;
    width: 100%;

    margin-bottom: 15px;
    padding: 10px;
    padding-top: 5px;
    border: 1px solid #DFDFDF;
    border-radius: 10px;
    font-size: 16px;

    p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.flowContact-created {
    grid-area: created;
    color: #989898;
    font-size: 12px;

}

.flowContact-name {
    grid-area: name;
    color: #272727;
    font-weight: bold;
}

.flowContact-enterprise {
    grid-area: enterprise;
    color: #989898;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.flowContact-mmsi {
    grid-area: mmsi;
    color: #989898;
    font-weight: 600;
}

.flowContact-mark {
    grid-area: mark;
    color: #272727;
    font-weight: bold;
}

.flowContact-btn {
    grid-area: btn;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 2px;
    padding-left: 6px;
    position: relative;

    &::after {
        position: absolute;
        content: '';
        height: 70%;
        width: 1px;
        background-color: #858796;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    svg {
        font-size: 16px;
        margin: 0 4px;
        transition: all 400ms ease;
        cursor: pointer;

        &:hover {
            transform: translateY(-3px);
        }
    }
}

// History Modal

.historyCont {
    font-size: 16px;

    textarea {
        width: 100%;
        height: 100px;
        resize: none;
        padding: 5px 10px;

        &::-webkit-scrollbar {
            height: 4px;
            width: 7px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #A9A9A9;
            border-radius: 30px;
        }
    }

    form {
        display: flex;
        flex-direction: column;
    }

    button {
        align-self: flex-end;
        margin-top: 10px;
    }

    li {
        list-style: disc;
        grid-row: 1 / span 2;

    }

    ul div {
        display: grid;
        grid-template-columns: 3fr 1fr;
        grid-gap: 10px;
        margin: 6px 4px;
    }

    ul {
        max-height: 300px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            height: 4px;
            width: 7px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #A9A9A9;
            border-radius: 30px;
        }
    }

    svg {
        cursor: pointer;
    }

    svg,
    p {
        justify-self: flex-end;
    }
}

//#endregion

//#region Contact View

.contactBody {
    display: grid;
    grid-template-rows: 70px 100px auto;
    background-color: white;

    header {
        // background-image: linear-gradient(90deg, #6A5BE2 10%, #1b1c30 100%);
        background-color: #6456d4;
        color: white;
        height: 70px;
        display: flex;
        align-items: center;
        padding: 20px;
    }

    h2 {
        font-size: 32px;
    }

    span {
        font-size: 16px;
    }

    p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

}



.contactViewContainer {
    margin-inline: 30px;
}

.contactViewCard,
.enterpriseViewCard {
    display: grid;
    grid-template-areas:
        "contactName contactEnterprise contactCallSign contactFlag contactBtn"
        "contactEmail contactEmail contactEmail contactEmail contactBtn"
    ;
    font-size: 16px;
    width: 100%;
    margin-bottom: 15px;
    background-color: #DADEDF;
    grid-template-columns: 2fr 2fr 2fr 1fr 1fr;
    border-radius: 10px;
    padding: 5px 20px;
    color: black;
    font-weight: 600;

    box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.24);
    -webkit-box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.24);

    &:nth-child(1) {
        margin-top: 15px;
    }

    &:nth-child(2n) {
        background-color: #A7AFB2;
        background-color: #b8bdbf;
    }
}

.contactsView-name {
    grid-area: contactName;
}

.contactsView-enterprise {
    grid-area: contactEnterprise;
}

.contactsView-email {
    grid-area: contactEmail;

    span {
        color: #777777;
    }
}

.contactsView-callSign {
    grid-area: contactCallSign;
}

.contactsView-flag {
    grid-area: contactFlag;
}

.contactsView-btn {
    grid-area: contactBtn;
    display: flex;
    justify-content: right;
    align-items: center;

    svg {
        margin: 0 10px;
        cursor: pointer;
        transition: all 400ms ease;

        &:hover {
            transform: translateY(-3px);
        }
    }
}

.paginateBtn {
    display: flex;
    justify-content: center;
    font-size: 24px;

    span {
        text-decoration: none;
        color: #777777;
        user-select: none;
        font-size: 22px;
        margin: auto 0;
        cursor: pointer;
        margin-inline: 10px;
    }
}

.searchCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-inline: 100px;

    div {
        display: flex;
        align-items: center;
    }

    input {
        border-radius: 15px;
        width: 300px;
        padding: 4px;
    }
}

.formBtn * {
    font-size: 16px;
}

//#endregion

// Sweet Alert

.swal2-popup {

    div,
    button {
        font-size: 16px;
    }
}

// Date Picker

.datePicker {
    color: #858796;

    svg {
        font-size: 24px;
        fill: #858796;
        top:4px;
    }
    *{
        font-size: 10px;
    }
    input{
        font-size: 14px;
    }
}


@keyframes upDown {
    50% {
        translate: 0 -6px;
    }

    100% {
        translate: 0;
    }
}