#alert{ //Alert cont
    background-color: rgba(0, 89, 222, .7);
    padding: 20px 40px;
    margin:0;
    width: 450px;

    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 1000;

    border-radius:4px;
    border-left: 6px solid $main-blue;
    // border-left: 6px solid $main-blue;
}

.showAlert{ //Show Alert
    animation: show_slide 1s ease forwards;
}

@keyframes show_slide{
    0%{
        transform: translateX(100%);
    }
    40%{
        transform: translateX(-10%);
    }
    80%{
        transform: translateX(0%);
    }
    100%{
        transform: translateX(0%);
    }
}
.hidden{
    display: none;
}
.hideAlert{
    animation: hide_slide 1.5s ease forwards; 
}

@keyframes hide_slide{
    0%{
        opacity: 1;
    }
    100%{
       opacity: 0; 
    }
}

.fa-circle-info {//Info Icon
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);

    color: $blue-text;
    font-size: 30px;
}

.msg{ //Alert msg
    padding: 0 20px ;
    font-size: 18px;
    color: $blue-text;
    color: white;
}

.close-btn{ //Alert button
    position: absolute;
    // background-color:rgba(0, 89, 222, .4);
    // border-radius: 4p;
    // padding: 15px;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    cursor: pointer;

    .fa-xmark{
        font-size: 25px ;
        text-align: center;
        color: $blue-text;
        color: black;
        &:hover{
            opacity: .7;
        }
    }
}