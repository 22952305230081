#contact{
    padding-bottom: 100px;
    h3{
        font-size: 50px;
        margin:50px 0 100px 0;
    }
	article{
		padding: 0;
		margin: 0;
	}
}
.inquiresInfo{
    // display: grid;
    // width: 100%;
    // grid-template-columns: 1fr 1fr 1fr;
	@include flex(flex, row, center, center);
	flex-wrap: wrap;
    a{
        font-size:28px;
        color: $blue-text;
        font-weight: 600;
    }
    p{
        font-size: 24px;
    }
    >div{
        @include flex(flex, column, center, center);
		margin: 15px 30px;
		div{
			@include flex(flex, row, center, center);
			i{
				font-size: 18px;
				margin-right: 5px;
			}
		}
    }

}

.contactFormCont{
    margin-top: 50px;
}


