*{
    margin: 0;
    padding: 0;
}
.preServiceSection{
    background-color: #ececec;
    h3{
        font-size: 25px;
    }
    h2{
        font-size: 30px;
        padding-block: 36px 20px;
        text-align: center;
        margin: 0;
    }
    p{
        font-size: 18px;
    }
}

.preServiceCont{
    border-radius: 30px;
    background-color: white;
    textarea{
        font-size: 18px;
        padding:4px 8px;
        height: 200px;
        margin-block: 40px;
    }
}