//Video Intro
#intro{
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 100px;
    
    video,img{
        width: 100%;
        aspect-ratio: 16/9;
    }
    
    }
.slide{
    max-height: calc(100vh - 100px);
    position: relative;
}
.absolute{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
}

.overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    position: absolute;
    background-color: rgb(5, 2, 28);
    opacity: 0.6;
    z-index: 1
}

.overlay2{
    @extend .overlay;
    background-color: rgb(0, 0, 0);
    opacity: 0.7;
}

.slide2Content{
    height: 100%;
    @include flex(flex, column, space-evenly, left);
    color: white;
    font-size: calc(10px + 2vw);
    span{
        background-color: $Cgold;
        color: $Cdark;
        padding: 0 4px;
    }
    h3{
        font-weight: 600;
        font-size: clamp(0em,1em, 60px);
        line-height: 1.5;
        text-align: left;
    }
    p{
        font-size: clamp(0em, .65em, 40px);

        font-weight: 500;
        line-height: 1.5;
        text-align: left;
    }
    a{
        align-self: flex-start;
        font-size: clamp(0em, .55em, 35px);

        font-weight: 500;
        padding: 12px;
        border-radius: 15px;
        border: 1px solid white;
        color: white;
        text-decoration: none;
        opacity: 0.8;
        transition: all 300ms;
        &:hover{
            scale: 1.05;
            opacity: 1;
        }
    }
}

.slide3Content{
    @extend .slide2Content;
    @include flex(flex, column, space-around, center);
    font-size: calc(15px + 1.5vw);

    p{
        text-align: center;
    }
    a{
        align-self: center;
    }
}
.slide1Content{
    @extend .slide2Content;
    h3{
        font-family: "Raph Lanok Future",sans-serif;
        font-weight: 400;
        font-size: calc(15px + 5.2vw);
    }
    p{
        text-align: center;
        font-weight: 400;
        font-size: calc(10px + .6vw);

    }
    a{
        align-self: center;
    }

}
.carousel-control-prev,.carousel-control-next{
    width: 10%;
    z-index: 100;
}


.video-title{
    margin-bottom: 1.2rem;
}



.absolute3{
    width: 100%;
    height: 100%;
    padding:0 300px;
    color: white;
    position: absolute;
    text-align: center;
    z-index: 100;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    span{
        background-color: $Cgold;
        color: $Cdark;
        padding: 0 4px;
    }
    h2{
        font-family: "Raph Lanok Future",sans-serif;
        @include video-title;
    }
    p{
        @include video-subtitle;
    } 
    h3{
        margin: 30px 0;
        font-weight: 600;
        font-size: 45px;
        line-height: 1.5;
        width: 50%;
    }

    h4{
        font-size: 18px;

        line-height: 1.5;
        width: 50%;
    }
    a{
        font-size: 20px;
        font-weight: 500;
        padding: 12px;
        border-radius: 15px;
        border: 1px solid white;
        color: white;
        text-decoration: none;
        opacity: 0.8;
        transition: all 300ms;
        &:hover{
            scale: 1.05;
            opacity: 1;
        }
    }
    div{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

