#about-us{
    background-color:$Clight ;
    max-width: 100vw;
    padding-bottom: 100px;
    article{
        margin: 0;
        padding: 0;
    }
}

.aboutCard{
    @include flex(flex, column, center, center);
    border-radius: 4px;  
    padding:30px 50px;
    background-color: #eeeeee;
    border: none;
    margin: 50px;
    box-shadow: 0px 10px 14px -4px rgba(0,0,0,0.4);
    -webkit-box-shadow: 0px 10px 14px -4px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px 10px 14px -4px rgba(0,0,0,0.4);  
    h3{
        margin-bottom: 30px;
        font-size: 30px;
    }
    p{
        font-size: 24px;
        text-align: justify;
    }
}

.aboutTitle{
    color: $main-blue
}


.about-us-p{
    @include about-p;
    text-align: justify;
    color: $blue-text;
    margin-bottom: 15px;
}


.certificaciones{
    margin: 50px 0;
    img{
        width: clamp(100px, 300px, 300px)
    }
}
.contadores{
        @include flex (flex, row, center, center);  
    }
    .cont{
            @include flex (flex, column, center, center);
            margin: 50px;
            div::before{
    content: "+";
    color: #852004;  
    }
    p{
        font-weight: 600;
        text-align: center;
        font-size: 20px
    }
}
.number{
        @include cont-n;
        color: $board;
    }

.ocultar{
        opacity: 0;
    }
.animar{
        animation: aparecer 1s;
}
//Count
@keyframes aparecer {
    0%{
            opacity: 0;
            transform: translateY(100px);
        }
    100%{
            opacity: 1;
            transform: translateY(0);
        }
    }

