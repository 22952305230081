.loginSection{
    height: 100vh;
    display: grid;
    place-items: center;
    background-color: #f2f2f2;
    h2{
        font-size: 28px;
        font-weight: 600;
    }
}
.loginCont{
    background-color: white;
    border-radius: 20px;
    display: flex;
    padding: 50px 20px;
}