h1{
    margin: 0;
    font-size: 40px;
    font-family: "Raph Lanok Future",sans-serif;
    font-weight: 400;
    padding: 20px 15px;
    text-decoration: none;
    color: #053c9b;
}
a{
    text-decoration: none;
}
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: white;
    margin-bottom: 20px;
    padding-inline: 10px;

    div{
        display: flex;
        flex-direction: row;
        align-items: center;
        img{
            width: 100px;
            padding: 15px;
        }
    }
    svg{
        font-size: 35px;
        color: #6f6f6f;
        margin-right: 40px;
        cursor: pointer;
    }
}